  <template>
  <div>
    <NotFound404Error />
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";

const props = defineProps({
  error: Object,
});
const nuxtApp = useNuxtApp();
const store = useMainStore();
// const CountryLocale = useCookie("CountryLocale");
const CountryLocale = nuxtApp.$cookies.set("CountryLocale");

onServerPrefetch(async () => {
  if (CountryLocale) {
    await store.loadTranslationJsonData(CountryLocale.replace("/", "-"));
  } else {
    await store.loadTranslationJsonData("int-en");
  }
});

const toDefaultPage = computed(() => {
  if (CountryLocale) {
    return `/${CountryLocale.replace("/", "-")}`;
  }
  return "/";
});
definePageMeta({
  layout: "blank",
});
</script>